import ReactModal from 'react-modal';
import ImageCropper from './ImageCropper/ImageCropper';
import css from './style.module.scss';

export default function Modal({title, modalComponent, modalContent, isOpen, handleClose}:{title?: string; modalComponent: string; modalContent:any; isOpen: boolean; handleClose: any}) {
  console.log('modalContent', modalContent)
  return <>
    <ReactModal isOpen={isOpen} onRequestClose={handleClose} preventScroll={true} ariaHideApp={false}
      className={css.modalContent} overlayClassName={css.modalOverlay}
    >
      {title && <h2>{title}</h2>} {/* @ts-ignore */}
      {modalComponent === 'ImageCropper' && <ImageCropper file={modalContent?.file} index={modalContent?.index} updateFile={modalContent?.updateFile} handleClose={handleClose}/>}
    </ReactModal>
  </>
}
