import React from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom';


export default function Header(props) {
    let history = useHistory();
    const location = useLocation();
    const path = location.pathname.toLocaleLowerCase();
    // console.log('location.pathname:', location.pathname, 'path:', path, 'loggedIn:', props.loggedIn)

    const logo = <div><h1>REACT TEMPLATE</h1></div>;
    const login = <Link to='/Login'> <h3>Login</h3></Link>;
    const logout = <button onClick={() => { logOut(); }}>Sign Out</button>;
    const register = <Link to='/Register'> <h3>Register</h3></Link>;

    const logOut = () => {
        sessionStorage.removeItem('token');
        history.push('/');
    }

    return (
        // idea- switch to !== path model, so that rather than manually specifying what goes where disable based off route 
        //and the rest will be shown in order
        <header>
            {props.loggedIn
                ? <> <Link to='/Dashboard' className="headerLogo">{logo}</Link>
                    <nav>
                        {logout}
                    </nav>
                </>
                : <>
                    <Link to='/' className="headerLogo">{logo}</Link>
                    <nav>
                        {!props.loading && !path.includes('/dashboard') && <>
                            {!path.includes('/login') && <>{login}</>}
                            {!path.includes('/register') && <>{register}</>}
                        </> }
                    </nav>
                </>
            }
        </header>
    );
}