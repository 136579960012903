//move full user object validator here- send object in, return true/false. 
//can use to update, just send current values on fields that are not being updated.
//doing validation only here means there is one set of rules (and also on the backend)
export const validateInputs = (user) => {
    if(user.first_name === "" && user.last_name === "" && user.email === ""){ alert('You must fill out all required forms'); return false; }
    if(user.first_name === "") { alert("You must enter your first name."); return false; }
    if(user.last_name === "") { alert("You must enter your last name."); return false; } 
    if(user.email === ""){ alert('You must enter an email.'); return false; }
    if (user.phone && !/^(?:\([2-9]\d{2}\) ?|[2-9]\d{2}(?:-?| ?))[2-9]\d{2}[- ]?\d{4}$/.test(user.phone)){ alert('Invalid phone number. Format: xxxxxxxxxx, xxx-xxx-xxxx or (xxx) xxx-xxxx'); return false; }
    if (!user.zip){ alert('Zip code is required'); return false; }
    if (!/^\d{5}$/.test(user.zip)){ alert('Invalid Zip Code. Format: xxxxx'); return false; }
    // if(user.invitation_code === '') { alert('You must enter an invitation code.'); return false; }
    // if(user.invitation_code !== '!quackhireguest') { alert('Invalid invitation code.'); return false; }
    return true;
};
export const isValidPassword = (password, confirmPassword) => {
    if (!password) { alert("You must enter a password."); return false; }
    if (!confirmPassword) { alert("You must confirm your password"); return false; }
    if (password !== confirmPassword){ alert('New password and password confirmation do not match.'); return false; }
    if (password.length < 5 || password.length > 20) { alert("Password cannot be less than 5 or greater than 20 characters"); return false; }
    if (!/(!|@|#|\$|&|\*|%|^)/.test(password)) { alert("Password must contain at least one special character"); return false; }
    if (!/([A-Z])/.test(password)) { alert("Password must contain at least one capitalized letter"); return false; }
    if (!/([a-z])/.test(password)) { alert("Password must contain at least one lowercase letter"); return false; }
    if (!/([0-9])/.test(password)) { alert("Password must contain at least one number"); return false; }
    return true;
};

export const isValidUUID = (uuid) => {
    if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid)){
        // alert('Invalid validation code.');
        return false;
    }
    else{ return true; }
}

export const getBaseUrl = () => {
    return alert('Update backend baseUrl in AppUtils.js');
}