import {useDropzone} from 'react-dropzone';
import css from './style.module.scss';

export default function Dropzone({setFiles, handleDrop, isMultiple}:{setFiles: any; handleDrop?: any; isMultiple?: boolean}) {
    const {isDragActive, getRootProps, getInputProps} = useDropzone({
        accept: 'image/*', multiple: isMultiple,
        onDrop: (acceptedFiles)=>{ handleDrop ? handleDrop(acceptedFiles) : setFiles(acceptedFiles); }
    });

    //size limit, file types, isMultiple
    //accepted file extensions by file type(switch/useEffect)

    return <>
        <div {...getRootProps()} className={css.test}>
            <input {...getInputProps()} />
            {isDragActive
                ? <p>{`Drop the files here...`}</p>
                : <p>{`Drag and drop files here, or click to select`}</p>
            }
        </div>
        {/* <aside>
            <h4>{`Files:`}</h4>
            <ul>{files}</ul>
        </aside> */}
    </>
}