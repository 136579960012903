import axios from 'axios';
import { getBaseUrl } from './AppUtils';

export default function axiosWithAuth() {
    return axios.create({
        baseURL: getBaseUrl(),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${sessionStorage.getItem('token')}`,
        },
    });
};