import { useState, createContext, Dispatch, SetStateAction } from 'react'; //@ts-ignore
import { Route } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute.js";
import Header from './components/Global/Header';
import LandingPage from "./components/Global/LandingPage.js";
import Login from "./components/Account/Login.js";
import Register from "./components/Account/Register.js";
import Verify from './components/Account/Verify.js';
import Dashboard from "./components/Dashboard/Dashboard.js";
import UploadForm from './components/Forms/UploadPage';

type AppContextInterface = {isModalOpen: boolean, setIsModalOpen: Dispatch<SetStateAction<boolean>>;} //@ts-ignore
export const AppContext = createContext<AppContextInterface>(undefined);

export default function App() {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return <div style={isModalOpen ? {maxHeight: '100vh', overflow: 'hidden'} : {}}>
    <AppContext.Provider value={{isModalOpen, setIsModalOpen}}>
      <Header/>
      <div style={{padding: '0 20px'}}>
        <UploadForm />
        {/* <Route exact path='/' component={LandingPage} /> */}
        {/* <Route path='/Login' render={props => <Login {...props} setLoading={setLoading} />} />
        <Route path='/Register' render={props => <Register {...props} setLoading={setLoading} />} />
        <Route path='/Verify/:verificationCode' render={props => <Verify {...props} setLoading={setLoading} />} /> */}
        <PrivateRoute path='/Dashboard' component={Dashboard} loading={loading} />
        <h2>{`REACT_APP_TEST: ${process.env.REACT_APP_TEST}`}</h2>
      </div>
    </AppContext.Provider>
  </div>
}