import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axiosWithAuth from '../../utils/axiosWithAuth.js';

export default function LandingPage() {
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState();
    const history = useHistory();

    console.log('password', password)

    const deleteUser = () => {
        setLoading(true);
        console.log('onDelete password:', password)
        axiosWithAuth().delete('/users/user', { data: {password} })
        .then(response => {
            console.log('dashboard deleteUser response:', response);
            setLoading(false);
            sessionStorage.removeItem('token');
            history.push('/');
        })
        .catch(err => {
            // console.log('user Jobs err:', err);
            console.log("dashboard deleteUser error:", err.response.data.message)
            setLoading(false);
        });
    }

    return (
        <div className="registerParent" style={{height: '400px'}}>
            <h1>(Dashboard, only visible if logged in)</h1>
            <br/>
            <p>Input your password and click the button to delete your account <br/> (so you can test registering again)</p>
            <br/>
            <input onChange={(e)=>{setPassword(e.target.value)}} placeholder={'Password'}></input>
            <br/>
            <button onClick={deleteUser}>Delete account</button>
        </div>
    )
}