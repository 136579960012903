import { Route, Redirect, useLocation } from 'react-router-dom';
import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";

export default function PrivateRoute({ component: Component, ...rest }) {
  const location = useLocation();
  const path = location.pathname.toLocaleLowerCase();

  const loggedIn = () => {
    if (sessionStorage.getItem('token')) { return true; }
    else{
      alert('You must be logged in to view this page.');
      return false;
    }
  }

  return (<>
    {(rest.loading && path.includes(rest.path))
      ? <StyledLoader active={rest.loading} spinner text='Loading...'/>
      : <Route {...rest} render={props => ( loggedIn() ? <Component {...props} {...rest} /> : <Redirect to="/Login" /> )} />
    }
    </>
  );
}

const StyledLoader = styled(LoadingOverlay)`
    min-height: calc(100vh - 95px);;
    width:100%;
    z-index: 2;
`;