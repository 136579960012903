import {useState, useEffect, useRef} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getCroppedImage } from './canvasUtils';
import modalCss from '../style.module.scss';
import css from './style.module.scss';


interface Crop{unit: string, x?: number, y?: number, width: number, height?: number};

export default function ImageCropper({file, index, updateFile, handleClose}:{file: File, index: number, updateFile: any; handleClose: any;}) {
    const [imageBlob, setImageBlob] = useState<any>(null);
    const cropRef = useRef(null);
    const [croppedImageBlob, setCroppedImageBlob] = useState<any>(undefined);
    const [crop, setCrop] = useState<Crop | null>({unit: '%', width: 30});
    const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);

    const handleCrop = async (completedCrop:Crop) => {
        const scale = calculateScale();
        const croppedImage = await getCroppedImage(imageBlob, completedCrop, scale);
        setCompletedCrop(completedCrop);
        setCroppedImageBlob(croppedImage);
    }

    const handleFile = async (file: File) => {
        if (file) { setImageBlob(await readFile(file)); }
        else{ setImageBlob(null); }
    }

    const readFile = (file: File)=>{
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', ()=>{ return resolve(reader.result);  })
            reader.readAsDataURL(file)
        })
    }
    
    useEffect(() => { handleFile(file); }, [file])

    const calculateScale = () => { //@ts-ignore
        const fullWidth = cropRef?.current?.imageRef?.current?.naturalWidth; //@ts-ignore
        const actualWidth = cropRef?.current?.imageRef?.current?.width; //@ts-ignore
        const fullHeight = cropRef?.current?.imageRef?.current?.naturalHeight; //@ts-ignore
        const actualHeight = cropRef?.current?.imageRef?.current?.height;
        return {x: fullWidth / actualWidth, y: fullHeight / actualHeight}
    }

    //this function does some math to cap the crop selector to the bounds of the image
    const handleChange = (c: Crop) => {
        if(!c){ return setCrop(null); } //@ts-ignore
        const imageWidth = cropRef?.current?.imageRef?.current?.width; //@ts-ignore
        const imageHeight = cropRef?.current?.imageRef?.current?.height;
        const localCrop = {...c}; //@ts-ignore
        if(c.x < 0){ localCrop.x = 0; } //@ts-ignore
        if((c.x !== crop.x) && ((c.x + c.width) > imageWidth)){ localCrop.x = imageWidth - c.width; } //@ts-ignore this fixes dragging the box outside the right image boundary
        else if((c.x + c.width) > imageWidth){ localCrop.width = imageWidth - c.x; } //@ts-ignore
        if(c.y < 0){ localCrop.y = 0; } //@ts-ignore
        if((c.y + c.height) > imageHeight){ localCrop.height = imageHeight - c.y; } //@ts-ignore
        setCrop({...localCrop});
    }

    const handleUpdateFile = () => {
        updateFile(index, croppedImageBlob);
        handleClose();
    }

    return <> {/* @ts-ignore */}
        <ReactCrop ref={cropRef} src={imageBlob} crop={crop} className={css.cropContainer} onChange={handleChange} onComplete={(c) => handleCrop(c)} />
        {croppedImageBlob && //Rounding is important so the canvas width and height matches/is a multiple for sharpness.
            <img src={URL.createObjectURL(croppedImageBlob)} style={{
                width: Math.round(completedCrop?.width ?? 0), height: Math.round(completedCrop?.height ?? 0),
                maxWidth: '100%', maxHeight: '260px', objectFit: 'contain'
            }}/>
        }
        <div className={modalCss.modalButtonRow} style={{position: 'absolute', bottom: '8', left: 'calc(50% - 150px)'}}>
          <button onClick={handleClose}>{`Cancel`}</button>
          <button onClick={handleUpdateFile} style={{color: 'white', backgroundColor: 'hsl(220, 100%, 60%)'}}>{`Crop Image`}</button>
        </div>
    </>
}