import { useContext, useState } from 'react';
import compressImage from 'browser-image-compression';
import { formatBytes } from '../../utils/stingUtils';
import Dropzone from '../Dropzone/Dropzone';
import css from './style.module.scss';
import Modal from '../Modal/Modal';
import { AppContext } from '../../App';


export default function UploadForm() {
  const [files, setFiles] = useState<File[] | undefined>(undefined);
  const [modalContent, setModalContent] = useState<any>(undefined);
  const {isModalOpen, setIsModalOpen} = useContext(AppContext);
  const handleDrop = (acceptedFiles: any) => { setFiles((prev)=>{ return prev ? [...prev, ...acceptedFiles] : [...acceptedFiles]}); }

  const compressImages = () => {
    if(!files || files?.length > 1){ return null; }
    else{
      //set loading message to 'Compressing Images..'
      return files.map(async (file)=>{
        await compressImage(file, {maxSizeMB: 2});
      })
    }
  }

  const handleSubmit = () => {
    //compressImages()
  }

  const removeFile = (index: number) => {
    if(!files || files?.length < 1){ return null; }
    else{
      const localArray = [...files];
      localArray?.splice(index, 1);
      setFiles([...localArray]);
    }
  }

  const updateFile = (index: number, updatedFile: Blob) => {
    console.log('updateFile firing, updatedFile blob:', updatedFile)
    if(!files || files?.length < 1){ return null; }
    else{
      const localArray = [...files];
      const newFile = new File([updatedFile], files[index].name);
      console.log('newFile', newFile)
      localArray?.splice(index, 1, new File([updatedFile], files[index].name));
      setFiles([...localArray]);
    }
  }

  const openCropModal = (index: number) => {
    if (!files || !files[index]){ return null; }
    setIsModalOpen(true);
    setModalContent({file: files[index], index, updateFile})
  }
  
  return <>
    <Dropzone setFiles={setFiles} handleDrop={handleDrop} isMultiple />
    {files && 
      <div className={css.files}>
        <h3>{`Files:`}</h3>
        {files.map((file, index)=>{
          return <li key={`${file.name}${index}`}>
                  <img src={URL.createObjectURL(file)}/>
                  <p>{`${file.name}`}</p>
                  <p>{`Size: ${formatBytes(file.size)}`}</p>
                  <div>
                    <button onClick={()=>{ openCropModal(index); }}>{`Crop`}</button>
                    <button onClick={()=>{ removeFile(index); }}>{`Remove`}</button>
                  </div>
                </li>
        })}
      </div>
    }
    <Modal title={'Crop Image'} modalComponent={'ImageCropper'} modalContent={modalContent} isOpen={isModalOpen} handleClose={()=>{ setIsModalOpen(false); }}/>
  </>
}
