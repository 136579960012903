

export async function getCroppedImage(imageSrc:any, pixelCrop:any, scale: any) {
    //@ts-ignore
    const image: HTMLImageElement = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');
    if(!canvasContext || !image){ return null; }

    const pixelRatio = window.devicePixelRatio;

    canvas.width = pixelCrop.width * pixelRatio * scale.x;
    canvas.height = pixelCrop.height * pixelRatio * scale.y;

    canvasContext.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    canvasContext.imageSmoothingQuality = 'high';

    canvasContext.fillStyle = '#ffffff';
    canvasContext.fillRect(0, 0, canvas.width, canvas.height);
    canvasContext.drawImage(
        image,
        pixelCrop.x * scale.x, pixelCrop.y * scale.y, canvas.width, canvas.height,
        0, 0, canvas.width, canvas.height
    )

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => { console.log('blob inside', resolve(blob)); }, 'image/png');
    })
}

const createImage = (url:string) =>{
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', error => reject(error))
      image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
      image.src = url
    })
}